import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

/* eslint-disable */

if (localStorage.getItem("principal") && JSON.parse(localStorage.getItem("principal")).token) {
	axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("principal")).token;
}
// console.log('axios authorization: ' + axios.defaults.headers.common['Authorization']);

export default new Vuex.Store({
	state: {
		principal: (localStorage.getItem("principal") && JSON.parse(localStorage.getItem("principal"))) || {},
		notification: {
			title: null,
			content: {},
			show: false
		},
		totalCart: localStorage.getItem("totalCart"),
		totalUnseenMessages: localStorage.getItem("totalUnseenMessages"),
		userSelection: {
			autenticationCode: null,
			personList: null,
			show: false
		},

	},
	mutations: {
		login(state, principal) {
			// console.log('mutation: login before state: ' + JSON.stringify(state) + " principal: " + JSON.stringify(principal));
			state.principal = principal;
			axios.defaults.headers.common["Authorization"] = "Bearer " + principal.token;
			localStorage.setItem("principal", JSON.stringify(principal));
			// console.log('mutation: login after state: ' + JSON.stringify(state, null, 4));
		},
		logout(state) {
			// console.log('mutation: logout before state: ' + JSON.stringify(state));
			state.principal = {};
			delete axios.defaults.headers.common["Authorization"];
			localStorage.removeItem("principal");
			// console.log('mutation: logout after state: ' + JSON.stringify(state));
		},
		showNotification(state, { title, content }) {
			state.notification.title = title;
			state.notification.content = content;
			state.notification.show = true;
		},
		hideNotification(state) {
			state.notification.show = false;
			state.notification.title = null;
			state.notification.content = {};
		},
		debug(state) {
			console.log("localStorage principal: " + localStorage.getItem("principal") + " state: " + JSON.stringify(state, null, 4));
		},
		getTotalCart(state) {
			if (this.getters.isAuthenticated) {
				axios.get("/system/cart/list/total").then(response => {
					state.totalCart = response.data;
					localStorage.setItem("totalCart", response.data);
				});
			}
		},
		getTotalUnseenMessages(state) {
			if (this.getters.isAuthenticated) {
				axios.get("/system/interest/list?type=Customer").then(response => {
					const filteredItems = response.data.filter(item => item[0].dataMap && item[0].dataMap.countUnseen && item[0].dataMap.countUnseen > 0);
					const totalUnseenMessages = filteredItems.length;
					state.totalUnseenMessages = totalUnseenMessages;
					localStorage.setItem("totalUnseenMessages", totalUnseenMessages);
				});
			}
		},
		setUserSelection(state, userSelection) {
			state.userSelection = userSelection;
			localStorage.setItem("userSelection", JSON.stringify(userSelection));
		}
	},
	actions: {
		login({ commit }, principal) {
			// console.log('action: login');
			return new Promise((resolve, reject) => {
				axios
					.post("/system/authentication/signin", {
						email: principal.email,
						password: principal.password,
						rememberMe: principal.rememberMe
					})
					.then(response => {
						let data = response.data;
						if (data) {
							if (data.token) {
								commit("login", data);
								resolve({ response: response, principal: data });
							} else if (data.personList){
								data.show = true;
								commit("setUserSelection", data);
							}
						}
					})
					.catch(error => reject(error));
			});
		},
		relogin({ commit }, item) {
			// console.log('action: login');
			return new Promise((resolve, reject) => {
				axios
					.post("/system/authentication/signin1", {
						personId: item.personId,
						secretId: item.secretId,
						secretKey: item.secretKey,
						password: item.password
					})
					.then(response => {
						let principal = response.data;
						if (principal && principal.token) {
							commit("login", principal);
							resolve({ response: response, principal: principal });
							commit("setUserSelection", {autenticationCode: null, personList: null, show: false});
							
						}
					})
					.catch(error => reject(error));
			});
		},
		logout({ commit }) {
			// console.log('action: logout');
			return new Promise((resolve, reject) => {
				axios
					.post("/system/authentication/logout", {})
					.then(response => {
						commit("logout");
						resolve(response);
					})
					.catch(error => reject(error));
			});
		},
		debug({ commit }) {
			// console.log('action: debug');
			commit("debug");
		}
	},
	getters: {
		isAuthenticated: state => state.principal && state.principal.token
	}
});
